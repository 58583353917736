



















































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import useAbstract from "@/use/abstract";
import { v4 as uuid } from "uuid";

export default defineComponent({
  props: {
    abstract: {
      type: Object,
      required: false,
      default: {},
    },
  },

  setup(props, { root, emit }) {
    const { getTypeName, getStatusName } = useAbstract({ root });

    const state = reactive({
      loading: false,
      empty: false,
      loaded: false,
      error: false as boolean | number,
      invoice: "",
      reviewers: [],
      total: 0,
      buttonDisabled: true,
    });

    const model = reactive({
      selectedReviewers: [],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchReviewers = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant`, {
          params: { type: undefined, group: "Recenzent" },
        })
        .then(({ data: { participants, total } }) => {
          state.empty = false;
          state.reviewers = participants;
          state.total = total;
          model.selectedReviewers = props.abstract.reviewers
            ? props.abstract.reviewers.map((r: any) => r.id)
            : undefined;
        })
        .catch((error) => {
          if (error.response?.status === 404) {
            state.empty = true;
            state.reviewers = [];
            state.total = 0;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchReviewers);

    const saveReviewers = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        abstracts: [props.abstract.id],
        reviewers: model.selectedReviewers,
      };

      state.loading = true;

      axiosInstance
        .put(`event/${root.$route.params.id}/abstract/single-assign`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.abstract.reviewersAssign.success"),
          });

          setTimeout(() => {
            emit("close");
            emit("fetch");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const buttonDisabled = () => {
      if (model.selectedReviewers.length >= 1 && state.reviewers.length >= 1) {
        state.buttonDisabled = false;
      } else {
        state.buttonDisabled = true;
      }
    };

    watch(
      () => model.selectedReviewers,
      () => buttonDisabled()
    );

    return {
      state,
      model,
      getTypeName,
      getStatusName,
      saveReviewers,
    };
  },
});
